import React, { Component } from "react";
import style from "./Games.module.css";

class Games extends Component {
	render() {
		return <div className={style.blog}>Games</div>;
	}
}

export default Games;
