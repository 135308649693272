import React, { Component } from "react";
import style from "./About.module.css";

class About extends Component {
	render() {
		return <div className={style.dc}>About</div>;
	}
}

export default About;
